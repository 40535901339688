














import { Component, Prop, Vue } from 'vue-property-decorator';
import DisclaimerMd from '@/docs/disclaimer.md';
import VueMarkdown from 'vue-markdown';

@Component({
  components: {
    VueMarkdown,
  }
})
export default class TermsPage extends Vue {
  get termPoclicyContent(): string {
    return DisclaimerMd;
  }
}
